import { ComponentOptionsMixin } from 'vue';

const stopScrollModal: ComponentOptionsMixin = {
  name: 'stop-scroll-open-modal',
  props: {
    enabledScroll: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      root: null,
    };
  },
  mounted() {
    if (this.enabledScroll) return;
    this.root = document.documentElement;
    this.root.classList.add('overflow-y-hidden');
  },
  unmounted() {
    if (this.enabledScroll) return;
    this.root.classList.remove('overflow-y-hidden');
    this.root = null;
  },
};

export default stopScrollModal;
